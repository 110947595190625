import {createBrowserRouter, createRoutesFromElements, redirect, Route, RouterProvider} from "react-router-dom";
import PublicLayout from "./components/layouts/PublicLayout";
import {TempHome} from "./components/temp/UnderConstruction";
import Artists from "./components/pages/Artists";
import FAQ from "./components/pages/FAQ";
import AdminLogin from "./components/auth/AdminLogin";
import React from "react";
import AuthLayout from "./components/auth/AuthLayout";
import ErrorPage from "./components/errors/ErrorPage";
import {ProtectedLayout} from "./components/auth/ProtectedLayout";
import {adminLoader, ArtistHome} from "./components/admin/ArtistHome";
import AppointmentCreateV2, {createActionV2, createLoaderV2} from "./components/forms/createV2/AppointmentCreateV2";
import {RouteAuth} from "./components/auth/RouteAuth";
import {AppointmentAdmin, appointmentAdminAction, appointmentAdminLoader} from "./components/admin/AppointmentAdmin";
import {SettingsAdmin, settingsAdminLoader} from "./components/admin/SettingsAdmin";
import {ScheduleAdmin} from "./components/admin/ScheduleAdmin";
import {
    createFlashSetAction,
    FlashAdmin,
    flashAdminLoader
} from "./components/admin/flash/FlashAdmin";
import AppointmentScheduleV2, {
    scheduleActionV2,
    scheduleLoaderV3
} from "./components/forms/createV2/AppointmentScheduleV2";
import BookingWrapper, {bookingLoader} from "./components/forms/createV2/BookingWrapper";
import {EditFlashSet, editFlashSetAction, editFlashSetLoader} from "./components/admin/flash/EditFlashSet";
import {RootLayout} from "./components/layouts/RootLayout";
import AppointmentDeposit, {depositAction} from "./components/forms/createV2/AppointmentDeposit";
import LoadingPage from "./components/util/LoadingPage";
import {ClientAppointment, clientAppointmentLoader} from "./components/pages/appointment/ClientAppointment";
import {ModifyAppointment} from "./components/pages/appointment/ModifyAppointment";
import {Reschedule, rescheduleAction} from "./components/pages/appointment/Reschedule";
import {redirectBookNow, RedirectBookNow} from "./components/pages/RedirectBookNow"

// redirect bookings to home when shop is closed
const redirectHome = () => { return redirect("/")}

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/"
               element={<RootLayout />}
               errorElement={<ErrorPage/>}>
            <Route element={<PublicLayout/>} errorElement={<ErrorPage/>}>
                <Route index element={<TempHome/>}/>
                <Route path="artist/erika" element={<Artists artistName="erika"/>}/>
                <Route path="artist/calista" element={<Artists artistName="calista"/>}/>
                <Route path="faq" element={<FAQ/>}/>
                <Route
                    path="book-now"
                    element={<AppointmentCreateV2/>}
                    // loader={createLoaderV2}
                    loader={redirectHome}
                    action={createActionV2}/>
                <Route path="booking/:appointmentId"
                       element={<BookingWrapper/>}
                       errorElement={<ErrorPage/>}
                       loader={bookingLoader}>
                    <Route index path="schedule"
                           element={<AppointmentScheduleV2/>}
                           loader={scheduleLoaderV3}
                           action={scheduleActionV2}/>
                    <Route path="deposit"
                           element={<AppointmentDeposit/>}
                           action={depositAction}/>
                </Route>
                <Route
                    path={"appointment/manage/:appointmentId"}
                    element={<ClientAppointment />}
                    loader={clientAppointmentLoader}>
                    <Route index element={<ModifyAppointment />}/>
                    <Route path={"reschedule"} element={<Reschedule />}
                        loader={scheduleLoaderV3} action={rescheduleAction}/>
                </Route>
                <Route path={"appointment/create"}
                    // loader={redirectBookNow}
                    loader={redirectHome}
                />
            </Route>
            <Route element={<AuthLayout/>}
                   errorElement={<ErrorPage/>}>
                <Route path="login" element={<AdminLogin/>}/>
                <Route element={<ProtectedLayout/>}
                       errorElement={<ErrorPage/>}>
                    <Route
                        path="admin"
                        id={"admin"}
                        loader={adminLoader(RouteAuth.getAuth)}
                        element={<ArtistHome/>}>
                        <Route
                            index
                            loader={appointmentAdminLoader(RouteAuth.getAuth)}
                            action={appointmentAdminAction(RouteAuth.getAuth)}
                            element={<AppointmentAdmin/>}/>
                        <Route
                            path={"settings"}
                            loader={settingsAdminLoader(RouteAuth.getAuth)}
                            element={<SettingsAdmin/>}/>
                        <Route
                            path={"schedule"}
                            element={<ScheduleAdmin/>}/>
                        <Route
                            path={"flash"}
                            loader={flashAdminLoader(RouteAuth.getAuth)}
                            action={createFlashSetAction(RouteAuth.getAuth)}
                            element={<FlashAdmin/>}>
                            <Route
                                path={"edit/:setId"}
                                loader={editFlashSetLoader(RouteAuth.getAuth)}
                                action={editFlashSetAction(RouteAuth.getAuth)}
                                element={<EditFlashSet/>}
                            />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Route>
    )
);

export default function App() {
    return (
        <RouterProvider router={router} fallbackElement={<LoadingPage />}/>
    );
}


