import Container from "react-bootstrap/Container";

import {
    FaInstagram
} from "react-icons/fa";
import {Stack} from "react-bootstrap";

export function TempHome() {
    return (
        <Container fluid className={"background-container d-flex flex-column flex-grow-1"}>
            <Container className={"bg-success d-flex flex-column text-light rounded-3 p-3"}>
                <h1 className={"mx-auto"}>Thank You!</h1>

                <p>
                    The time has come for us to move on to a new chapter of our lives, so Botanical Ink in Boise is
                    now closed.
                </p>
                <p>
                    We appreciate each and every once of you that we had the privilege to meet and tattoo. Please
                    follow our personal Instagram accounts to find out what we're doing next!
                </p>
                <a href="https://www.instagram.com/erika.tattoo.art/" className={"mx-auto text-light fs-5 mb-3 text-decoration-none"}>
                    <Stack  direction="horizontal" gap={3}>
                        <FaInstagram size={40}/>
                        Erika Holmberg
                    </Stack>
                </a>
                <a href="https://www.instagram.com/tattoos.by.calista/" className={"mx-auto text-light fs-5 mb-3 text-decoration-none"}>
                    <Stack direction="horizontal" gap={3}>
                        <FaInstagram size={40}/>
                        Calista Doran
                    </Stack>
                </a>
                {/*<Image*/}
                {/*    className=""*/}
                {/*    src={shopPic}*/}
                {/*    style={{*/}
                {/*        maxWidth: "100%",*/}
                {/*        maxHeight: "100%",*/}
                {/*    }}*/}
                {/*    alt="Picture of the shop"*/}
                {/*/>*/}
            </Container>
        </Container>
    );
}